.LoadingPageHomeOffices {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: white;
  font-weight: normal;
  z-index: 100;
}
.LoadingPageHomeOffices .loadingText {
  font-size: 11px;
}
.LoadingPageHomeOffices .loadingContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.LoadingPageHomeOffices .videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  overflow: hidden; /* Ensure no overflow */
}
.LoadingPageHomeOffices .loadingVideo {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=LoadingPageHomeOffices.css.map */