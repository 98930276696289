.Artists .artists-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Artists .artists-list .artist-box {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 2px;
  text-align: center;
  max-width: 200px;
}
.Artists .artists-list img.thumb {
  width: 200px;
  height: 200px;
}/*# sourceMappingURL=Artists.css.map */