.PageGallery {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* index mismatch here... */
}
.PageGallery .pagesBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
}
.PageGallery .pagesContainer {
  display: flex;
  width: 100%;
  flex: 1;
  background-repeat: no-repeat;
}
.PageGallery .flipped {
  transform: rotateY(-180deg);
}
.PageGallery .page {
  background: transparent;
  position: absolute;
  height: 100%;
  width: 50%;
  backface-visibility: hidden;
  transition: transform 0.5s;
  background-repeat: no-repeat;
}
.PageGallery .page:nth-child(odd) {
  transform-origin: top right;
}
.PageGallery .page:nth-child(even) {
  margin-left: 50%;
  transform-origin: top left;
}
.PageGallery .page.even {
  background-repeat: no-repeat;
  background-color: "black";
  left: 0;
  background-position: center left; /* Adjust as needed */
}
.PageGallery .page.odd {
  background-repeat: no-repeat;
  background-color: "black";
  right: 0;
  background-position: center right; /* Adjust as needed */
}
.PageGallery .page-corner {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 25px solid transparent; /* Adjust size as needed */
  border-bottom: 25px solid black; /* Adjust size as needed */
}
.PageGallery .even .page-corner {
  left: 0;
  border-right: 25px solid transparent; /* Adjust size as needed */
  border-left: 25px solid black; /* Adjust size as needed */
}
.PageGallery .odd .page-corner {
  right: 0;
  border-right: 25px solid black; /* Adjust size as needed */
  border-left: 25px solid transparent; /* Adjust size as needed */
}
.PageGallery .page-corner-up {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 25px solid rgba(0, 0, 0, 0.2509803922); /* Adjust size as needed */
  border-bottom: 25px solid transparent; /* Adjust size as needed */
}
.PageGallery .even .page-corner-up {
  left: 0;
  border-right: 25px solid rgba(0, 0, 0, 0.2509803922); /* Adjust size as needed */
  border-left: 25px solid transparent; /* Adjust size as needed */
}
.PageGallery .odd .page-corner-up {
  right: 0;
  border-right: 25px solid transparent; /* Adjust size as needed */
  border-left: 25px solid rgba(0, 0, 0, 0.2509803922); /* Adjust size as needed */
}
.PageGallery .pageButtons {
  position: absolute;
  bottom: 55px;
  width: 100%;
  z-index: 100;
}
.PageGallery .pageButtons button {
  position: absolute;
}
.PageGallery .pageButtons button#backButton {
  left: 10px;
}
.PageGallery .pageButtons button#nextButton {
  right: 10px;
}
.PageGallery .pageButtons .page-button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  pointer-events: none;
}
.PageGallery .pageButtons .page-button:hover {
  background-color: #007bff;
  color: white;
}
.PageGallery .pageButtons .page-button-over {
  background-color: #007bff;
  color: white;
}

.GallerySketches .canvasPage {
  position: absolute;
  height: 100%;
  width: 50%;
  backface-visibility: hidden;
  transition: transform 0.5s;
}
.GallerySketches .canvasEven {
  transform-origin: top left;
}
.GallerySketches .canvasOdd {
  margin-left: 50%;
  transform-origin: top right;
}
.GallerySketches .canvas0Flipped {
  transform: rotateY(-180deg);
}
.GallerySketches .canvas1Flipped {
  transform: rotateY(-180deg);
}

.welcome-video-container {
  position: relative;
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome-video-container .video {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.welcome-video-container .video.playing {
  display: block;
}
.welcome-video-container .play-button {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10;
}/*# sourceMappingURL=GalleryPages.css.map */