$buttonSize: 50px;
$backgroundColor: "black";

.PageGallery {
  // display: flex;
  width: 100%;
  height: 100%;
  // flex: 1;
  position: absolute;
  top: 0;
  left: 0;

  .pagesBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;

    filter: blur(8px);
    -webkit-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    -ms-filter: blur(8px);
  }

  .pagesContainer {
    display: flex;
    width: 100%;
    flex: 1;
    background-repeat: no-repeat;
  }

  .flipped {
    transform: rotateY(-180deg);
  }

  .page {
    background: transparent;
    position: absolute;
    height: 100%;
    width: 50%;
    backface-visibility: hidden;
    transition: transform 0.5s;

    background-repeat: no-repeat;
    // background-size: 200%;
  }

  /* index mismatch here... */
  .page:nth-child(odd) {
    transform-origin: top right;
  }

  .page:nth-child(even) {
    margin-left: 50%;
    transform-origin: top left;
  }

  .page.even {
    background-repeat: no-repeat;
    background-color: $backgroundColor;
    left: 0;
    background-position: center left; /* Adjust as needed */
  }

  .page.odd {
    background-repeat: no-repeat;
    background-color: $backgroundColor;

    right: 0;
    background-position: center right; /* Adjust as needed */
  }

  .page-corner {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: $buttonSize/2 solid transparent; /* Adjust size as needed */
    border-bottom: $buttonSize/2 solid black; /* Adjust size as needed */
  }

  .even .page-corner {
    left: 0;
    border-right: $buttonSize/2 solid transparent; /* Adjust size as needed */
    border-left: $buttonSize/2 solid black; /* Adjust size as needed */
  }
  .odd .page-corner {
    right: 0;
    border-right: $buttonSize/2 solid black; /* Adjust size as needed */
    border-left: $buttonSize/2 solid transparent; /* Adjust size as needed */
  }

  .page-corner-up {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: $buttonSize/2 solid #00000040; /* Adjust size as needed */
    border-bottom: $buttonSize/2 solid transparent; /* Adjust size as needed */
  }

  .even .page-corner-up {
    left: 0;
    border-right: $buttonSize/2 solid #00000040; /* Adjust size as needed */
    border-left: $buttonSize/2 solid transparent; /* Adjust size as needed */
  }
  .odd .page-corner-up {
    right: 0;
    border-right: $buttonSize/2 solid transparent; /* Adjust size as needed */
    border-left: $buttonSize/2 solid #00000040; /* Adjust size as needed */
  }

  .pageButtons {
    position: absolute;
    bottom: 55px;
    width: 100%;
    z-index: 100;

    button {
      position: absolute;
      // height: 35; //$buttonSize;
      // width: 40; //$buttonSize;
    }

    button#backButton {
      left: 10px;
    }
    button#nextButton {
      right: 10px;
    }

    .page-button {
      margin: 0 5px;
      padding: 10px 15px;
      border: none;
      background-color: #f0f0f0;
      color: #333;
      font-size: 16px;
      pointer-events: none;
    }

    .page-button:hover {
      background-color: #007bff;
      color: white;
    }

    .page-button-over {
      background-color: #007bff;
      color: white;
    }
  }
}

.GallerySketches {
  .canvasPage {
    position: absolute;
    height: 100%;
    width: 50%;
    backface-visibility: hidden;
    transition: transform 0.5s;
  }
  .canvasEven {
    transform-origin: top left;
  }
  .canvasOdd {
    margin-left: 50%;
    transform-origin: top right;
  }

  .canvas0Flipped {
    transform: rotateY(-180deg);
  }

  .canvas1Flipped {
    transform: rotateY(-180deg);
  }
}

.welcome-video-container {
  position: relative;
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;

  .video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .video.playing {
    display: block;
  }

  .play-button {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    z-index: 10;
  }
}
