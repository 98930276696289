@import "../../styles/constants.scss";

$lightGray: rgba(0, 0, 0, 0.2);
$appBlueDisabled: #335ef699;
$appPink: #fc03ad;
$appLightBlue: #03cefc;

.Chat {
    position: absolute;
    height: 100vh;
    width: 100vw;
    pointer-events: none;

    .Frame .content {
        pointer-events: all;
    }


    .Chat-Content {
        // display: flex;
        // flex-direction: column;
        // flex: 1;
        width: 300px;
        height: 400px;
        color: white;
        // background-color: red;
        font-size: $fontSize-desktop-p2;

        .Chat-title {
            // flex: 0 0 auto;
            // height: auto;
            padding-bottom: 5px;
        }

        .Chat-messages {
            height: 400px;
            overflow: hidden;

            .Chat-messages-box {
                // font-size: $fontSize-desktop-p3;
                padding: 10px;

                min-height: 200px;
                flex: 1;
                overflow: auto;

                .Chat-messages-inner-box {

                    .message-from {
                        color: #ddd;
                    }

                    .message-to {
                        color: #ddd;
                    }

                    .message-to-red {
                        // color: $appAlertColor;
                        color: #ddd;
                    }

                    .message-deets {
                        // opacity: .6;
                        padding-bottom: 7px;
                        font-size: $fontSize-desktop-p3;
                    }

                    .message-txt {
                        font-size: $fontSize-desktop-p2;
                        line-height: 1.4em;
                    }

                    .bubbleContainer {
                        display: flex;
                        flex-direction: row;
                        padding-top: 10px;

                        .avatar {
                            width: 20px;
                            height: 20px;
                            font-size: 20px;
                            padding-top: 8px;
                            padding-right: 8px;
                        }

                        .hidden {
                            visibility: hidden;
                        }
                    }

                    .bubble {
                        border-radius: $borderRad;
                        padding: 10px;
                        border-radius: $borderRad;
                        // border: 1px solid $appBlue;
                        border: 1px solid $headerGradientStartC;
                        background: #111111;
                    }

                    .bubbleContainer.chat-from-me {
                        justify-content: flex-end;

                        .avatar {
                        }
                        .bubble {
                            // background: $appBlue;
                            background: $headerGradientStartC;
                        }
                    }

                    .bubbleContainer.chat-from-other {
                        justify-content: flex-start;

                        .bubble {
                        }
                    }
                }
            }

            .Chat-form {
                // flex: 0 0 auto;
                display: flex;
                flex-direction: column;
                padding: 10px;
                height: 90px;
                // background-color: pink;

                .to-form {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 5px;

                    .to-div {
                        // height: 48px;
                        width: 30px;
                        margin-top: 15px;
                        // line-height: 48px;
                        // vertical-align: middle;
                        padding-right: 10px;
                    }

                    .AutoComplete {
                        // flex: 1;
                        // display: flex;
                        height: 30px;
                        color: white;
                        // overflow: hidden;
                        background-color: transparent;

                        div {
                            input {
                                // flex: 1;
                                background-color: transparent;
                                // border-bottom: 2px solid $appBlue;
                                // border-radius: $borderRad;
                                padding: 5px;
                                color: white;
                                height: 30px;

                                &:focused {
                                    color: black;
                                }
                            }
                        }
                    }
                }

                .Chat-send {
                    display: flex;
                    flex-direction: row;
                    padding: 5px 0 5px 0;

                    .form-item {
                        // height: 30px;
                    }

                    .Chat-send-item {
                        flex: 1;
                        display: flex;
                        // padding-right: 5px;

                        input {
                            flex: 1;
                        }
                    }

                    button {
                        // border-radius: $borderRad;
                        width: 60px;
                        // border: 1px;

                        // background-color: transparent;
                        // border: 2px solid $appPink;

                        // &:disabled {
                        //   border: 1px solid gray;
                        // }
                    }
                }
            }
        }
    }
}
