.Artists {


    .artists-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

       

        .artist-box {
            // width: 350px;
            
            // padding: 10px;
           
            margin-right: 20px;
            margin-bottom: 20px;
            padding: 2px;
            text-align: center;
            max-width: 200px;
             // padding-bottom: 30px;
            // box-shadow: 10px 10px black;
            
        }

        img.thumb {
            width: 200px;
            height: 200px;
            // border: 2px solid white;
        }

        .artist-name {
            // text-align: center;
            // padding: 5px;
        }



    }

  
    
}