.Andy {
  background: white;
}
.Andy .berries {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;
  height: 300px;
  width: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  /* prevent selection highlight */
  -webkit-user-select: none;
  /* Safari */
  /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none;
  /* Standard syntax */
}
.Andy #berries1 {
  background-image: url("./assets/berries_1.png");
  transition: transform 180s;
}
.Andy #berries1:hover {
  transform: translate(-200px);
  transition: transform 3s;
}
.Andy #berries2 {
  background-image: url("./assets/berries_2.png");
  transition: transform 180s;
}
.Andy #berries2:hover {
  transform: translate(200px);
  transition: transform 3s;
}
.Andy #berries3 {
  background-image: url("./assets/berries_3.png");
  transition: transform 180s;
}
.Andy #berries3:hover {
  transform: translate(-200px);
  transition: transform 3s;
}
.Andy #berries4 {
  background-image: url("./assets/berries_4.png");
  transition: transform 180s;
}
.Andy #berries4:hover {
  transform: translate(200px);
  transition: transform 3s;
}
.Andy #berries5 {
  background-image: url("./assets/berries_5.png");
  transition: transform 180s;
}
.Andy #berries5:hover {
  transform: translate(-200px);
  transition: transform 3s;
}
.Andy .mobileBerries #berries1:hover {
  transform: translate(0, -180px);
}
.Andy .mobileBerries #berries2:hover {
  transform: translate(0, 180px);
}
.Andy .mobileBerries #berries3:hover {
  transform: translate(0, -180px);
}
.Andy .mobileBerries #berries4:hover {
  transform: translate(0, 180px);
}
.Andy .mobileBerries #berries5:hover {
  transform: translate(0, -180px);
}/*# sourceMappingURL=Andy.css.map */