@import "./styles/reset.scss";
@import "./styles/constants.scss";
@import "./styles/myWindows98.scss";

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

a.standardButton,
button.standardButton {
  font-size: $fontSize-desktop-p2;
  padding: 10px;

  min-width: 140px;

  text-align: center;
  // border: 1px solid black;
  color: black;
  border-radius: $borderRad;

  border: 2px solid black;
  // box-shadow: -3px 5px rgb(65, 65, 65);
  color: white;

  transition: all 0.2s ease-in-out;
  &:hover {
    // transform: translateY(-0.25em);
    transform: scale(1.05);
  }

  &.primary {
    background: black;
    // background: linear-gradient(#000, #1);
    color: white;
  }

  &.secondary {
    background-color: white;
    color: black;
    border: 2px solid black;
  }
}

a.btn,
button {
  font-size: $fontSize-desktop-p2;
}

input {
  font-size: $fontSize-desktop-p2;
}

input.standardInput {
  font-size: $fontSize-desktop-p2;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid black;
  color: black;
  border-radius: $borderRad;
}

input.standardInputWhite {
  font-size: $fontSize-desktop-p2;
  padding: 15px 10px;
  text-align: left;
  border: 2px solid white;
  color: white;
  border-radius: $borderRad;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0000004d;
  border-radius: $borderRad;
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: $scrollbarHoverColor;
// }

a {
  color: cyan;
}

h1 {
  font-size: $fontSize-h1;
  padding: 20px 0 20px 0;
  line-height: 60px;
}

h2 {
  font-size: $fontSize-h2;
  padding: 10px 0 10px 0;
  line-height: $fontSize-h2 + 6;
}

h3 {
  font-size: $fontSize-h3;
  padding: 10px 0 10px 0;
  line-height: $fontSize-h3 + 6;
}

h4 {
  font-size: $fontSize-h4;
  padding: 8px 0 8px 0;
  line-height: $fontSize-h4 + 4;
}

p {
  font-size: $fontSize-desktop-p1;
  line-height: 22px;
  padding: 15px 0 15px 0;
}

p.small {
  font-size: $fontSize-desktop-p2;
}

.container {
  flex: 1;
  padding-top: 50px;
  padding-bottom: 70px;

  width: 95%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
}

.windows {
  box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a,
    inset -2px -2px #dfdfdf, inset 2px 2px #808080;
  padding: 20px;
  // padding: 20px;
  background: #5a43cd6b;
  box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey,
    inset 2px 2px #dfdfdf;
}

hr {
  border: 1px solid white;
}
