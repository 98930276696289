@import "../../styles/constants.scss";

.Gallery {
  background-color: black;
  background-repeat: repeat;
  background-size: 700px 700px;

  .MiniMap {
    img {
      user-select: none;
    }

    .otherAvatarsMiniMap {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 200px;
      height: 200px;

      .mini-avatars {
        position: relative;
        width: 200px;
        height: 200px;

        .emojiMiniMap {
          position: absolute;
          font-size: 12px;
          margin-top: -6px;
          margin-left: -6px;
          width: 20px;
          height: 20px;
          // z-index: 600;
        }
      }
    }
  }
}
