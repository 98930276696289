.Popups {
  width: 100%;
  height: 100%;
}
.Popups .adVideoContainer {
  width: 100%;
  height: 100%;
}
.Popups .adVideo {
  width: 101%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=Popups.css.map */