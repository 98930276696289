@import "../../styles/constants.scss";


.RoomDecal {
    display: flex;
    flex-direction: column;
    line-height: 1.3em;
    flex: 1;
    z-index: 3;

   
    .decal-content {
        padding: 15px;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: auto;

        .identify {
            // flex-grow: 1;
            margin: auto;

            h2 a {
                color: blue;
                // background-color: black;
                // padding: 0px;
                // margin-bottom: 10px;
            }
        }

        .workTitle {
            font-size: 150%;
        }

        .usersEye {
            padding: 8px;
            border: 1px solid black;
            border-radius: $borderRad;
            display: inline-block;
        }
        .participants-box {
            // padding: 15px;
            // border: 1px solid black;
            // border-radius: 6px;
            // flex: 1;
            width: 100%;
        }
    }

    
}
