.Andy {
    background: white;


    .berries {
        position: fixed;
        left: 50%;
        top: 50%;
        margin-left: -150px;
        margin-top: -150px;
        height: 300px;
        width: 300px;
        background-size: contain;
        background-repeat: no-repeat;

        /* prevent selection highlight */
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    

    #berries1 {
        background-image: url("./assets/berries_1.png");
        transition: transform 180s;
    }

    #berries1:hover {
        transform: translate(-200px);
        transition: transform 3s;
    }

    #berries2 {
        background-image: url("./assets/berries_2.png");
        transition: transform 180s;
    }

    #berries2:hover {
        transform: translate(200px);
        transition: transform 3s;
    }

    #berries3 {
        background-image: url("./assets/berries_3.png");
        transition: transform 180s;
    }

    #berries3:hover {
        transform: translate(-200px);
        transition: transform 3s;
    }

    #berries4 {
        background-image: url("./assets/berries_4.png");
        transition: transform 180s;
    }

    #berries4:hover {
        transform: translate(200px);
        transition: transform 3s;
    }

    #berries5 {
        background-image: url("./assets/berries_5.png");
        transition: transform 180s;
    }

    #berries5:hover {
        transform: translate(-200px);
        transition: transform 3s;
    }

    .mobileBerries {

        #berries1:hover {
            transform: translate(0, -180px);
        }

        #berries2:hover {
            transform: translate(0, 180px);
        }

        #berries3:hover {
            transform: translate(0, -180px);
        }
    
        #berries4:hover {
            transform: translate(0, 180px);
        }
    
        #berries5:hover {
            transform: translate(0, -180px);
        }
    }
}