@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap");
.Everest {
  background: white;
}

.EverestOG {
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 100%;
  font-family: "Crimson Text", sans-serif;
  font-size: 1.2em;
}
.EverestOG .titleEl {
  text-align: center;
}
.EverestOG details summary {
  cursor: pointer;
}
.EverestOG em {
  font-style: italic;
}
.EverestOG details {
  padding-left: 1.5vw;
  display: block;
  line-height: 2em;
}
.EverestOG details > summary {
  list-style-type: none;
}
.EverestOG summary::-webkit-details-marker {
  display: none;
}
.EverestOG summary::after {
  content: " ►";
  padding-left: 1em;
  font-size: 0.7em;
}
.EverestOG details[open] > summary::after {
  transform: rotate(90deg);
}
.EverestOG details[open] > summary:after {
  content: " ▼";
  font-size: 0.7em;
}
@keyframes details-show {
  from {
    opacity: 0;
  }
}
.EverestOG details[open] > *:not(summary) {
  animation: details-show 250ms;
}
.EverestOG .glitchcity {
  margin-left: -35vw;
}
.EverestOG .indent {
  width: 20vw;
  min-width: 200px;
  padding-left: 3vw;
}
.EverestOG hr {
  border: 1px dashed black;
  width: 30vw;
}/*# sourceMappingURL=Everest.css.map */