.Artist {
    .door {
        // background: black;
        font-size: 150%;
        padding-bottom: 80px;
    }

    .links {
        font-size: 200%;
    }

    // h3 {
    //     // border-bottom: 1px solid white;
    // }

    
}
