.LoadingPage {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-weight: normal;
  z-index: 1;
}
.LoadingPage a {
  color: darkcyan;
}
.LoadingPage .title {
  font-size: 50px;
  padding-bottom: 20px;
}
.LoadingPage .loadingSymbol {
  font-size: 20px;
}/*# sourceMappingURL=LoadingPage.css.map */