.LoadingPageHomeOffices {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);

  text-align: center;
  color: white;
  font-weight: normal;
  z-index: 100;

  .loadingText {
    font-size: 11px;
  }

  .loadingContainer {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    overflow: hidden; /* Ensure no overflow */
  }

  .loadingVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // margin: 0;
    // padding: 0;
    // display: block;
    // clip-path: inset(0% 5% 0% 6%); /* Adjust as needed to crop */
  }
}
