@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');

.Everest {
    background: white;
}

.EverestOG {
    background: white;
    color: black;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    // display: block;
    width: 100%;
    font-family: 'Crimson Text', sans-serif;
    // margin-right: 5px;
    font-size: 1.2em;

  
    .titleEl {
        text-align: center;
    }

    details summary {
        cursor: pointer;
    }

    em {
        font-style: italic;
    }


    details {
        padding-left: 1.5vw;
        display: block;
        line-height: 2em;
    }

    details>summary {
        list-style-type: none;
    }

    summary::-webkit-details-marker {
        display: none
    }

    summary::after {
        content: ' ►';
        padding-left: 1em;
        font-size: .7em;
    }

    details[open]>summary::after {
        transform: rotate(90deg);
    }

    details[open]>summary:after {
        content: ' ▼';
        font-size: .7em;
    }


    @keyframes details-show {
        from {
            opacity: 0;
        }
    }

    details[open]>*:not(summary) {
        animation: details-show 250ms;
    }

    .glitchcity {
        margin-left: -35vw;
    }

    .indent {
        width: 20vw;
        min-width: 200px;
        padding-left: 3vw;
    }


    hr {
        border: 1px dashed black;
        width: 30vw;

    }
}