.Popups {
  width: 100%;
  height: 100%;

  //   position: absolute;
  .adVideoContainer {
    width: 100%;
    height: 100%;
  }
  .adVideo {
    width: 101%;
    height: 100%;
    object-fit: cover;
  }
}
